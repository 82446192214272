<template>
  <b-card no-body>

    <b-tabs content-class="mt-1">
      <b-tab title="Defects Category">
        <vehicle_defects_categoryVue />
    </b-tab>
      <b-tab title="Vehicle Defects">
        <b-card-body>
          <b-row style="display: none">
            <b-col cols="3">
              <b-form-group label="Category" label-for="category">
                <v-select
                  v-model="vehicleCategoryfilter"
                  label="label"
                  :options="vehicleCategoryOptions"
                  @input="onChange()"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <div>
                <b-form-group label="Action" label-for="action">
                  <b-button variant="primary" @click="filterAllData"
                    ><feather-icon icon="filterIcons" /> Filter</b-button
                  >

                  <b-button variant="danger" @click="resetAllData"
                    ><feather-icon icon="filterIcons" /> Reset</b-button
                  >
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <div class="d-flex">
            <b-button
              v-b-modal.modal-center-new
              class="mr-2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="onAddVehcileDefect"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              Add Vehicle Defects
            </b-button>
          </div>
          <!-- <br /> -->
          <!-- <div class="d-flex justify-content-between flex-wrap">
            <b-form-group
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mr-1 mb-md-0"
            >
            </b-form-group>

            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>
          </div> -->
        </b-card-body>
        <br />
        <!-- <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="history_list"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(action)="data">
            <b-button
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              title="Edit Defect"
              @click="onEdit(data.item)"
              style="
                position: relative;
                padding: 8px;
                width: 31px;
                margin-top: 0px !important;
              "
              v-b-modal.modal-center-update-new
            >
              <feather-icon icon="EditIcon" class="mr-50" title="Edit Defect" />
            </b-button>
            <b-button
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              style="
                      position: relative;
                      padding: 8px;
                      width: 31px;
                      margin-top: 0px !important;
                    "
                    @click="onDeleteDefect(data.item)"
              >
              <feather-icon icon="TrashIcon" class="mr-50" title="Delete Defect" />
            </b-button>            
          </template>
        </b-table> -->


        <table class="table table-striped vehicle_defects_table">
                <thead>
                  <tr>
                    <template >
                      <th scope="col">Title</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </template>
                  </tr>
                </thead>
                <draggable v-model="history_list" tag="tbody" @change="colChangeNew">
                  <tr v-for="(item, idx) in history_list" :key="idx">
                    <td>{{ item.title }}</td>
                    <td>{{ item.status == 1 ? "Active" : "In-Active" }}</td>
                    <td>
                      <b-button
                    class="mt-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    title="Edit Defect Category"
                    @click="onEdit(item)"
                    style="
                      position: relative;
                      padding: 8px;
                      width: 31px;
                      margin-top: 0px !important;
                    "
                    v-b-modal.modal-upload-files
                    
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                      title="Edit Defect Category"
                    />
                  </b-button>
                  <b-button
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              style="
                      position: relative;
                      padding: 8px;
                      width: 31px;
                      margin-top: 0px !important;
                    "
              @click="onDeleteDefectCategory(item)"
              >
              <feather-icon icon="TrashIcon" class="mr-50" title="Delete Defect Category" />
            </b-button>
                    </td>
                  </tr>
                </draggable>
            </table>
            

     

        <b-modal
          size="lg"
          id="modal-center-new"
          centered
          title="Add Defect"
          ok-only
          ok-title="Cancel"
          ref="modal-center-new"
        >
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Vehcile Category"
                label-for="h-vehicle-category"
                label-cols-md="4"
              >
                <v-select
                  id="h-vehicle-category"
                  v-model="vehicleCategory"
                  label="label"
                  :options="vehicleCategoryOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <b-form-group label="Title" label-for="h-title" label-cols-md="4">
                <b-form-input
                  v-model="title"
                  id="h-title"
                  placeholder="Enter Trailer Defect Title.."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-form-group
                label="Status"
                label-for="h-status-name"
                label-cols-md="4"
              >
                <v-select
                  id="h-status-name"
                  v-model="status"
                  label="label"
                  :options="statusOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-form-group
                label=""
                label-for="h-status-name"
                label-cols-md="4"
              >
                <b-button class="btn-sm" variant="primary" @click="onSave">
                  Submit
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal
          size="lg"
          id="modal-center-update-new"
          centered
          title="Edit Vehicle Defect"
          ok-only
          ok-title="Cancel"
          ref="modal-center-update-new"
        >
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Vehcile Category"
                label-for="h-vehicle-category"
                label-cols-md="4"
              >
                <v-select
                  id="h-vehicle-category"
                  v-model="vehicleCategory"
                  label="label"
                  :options="vehicleCategoryOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <b-form-group label="Title" label-for="h-title" label-cols-md="4">
                <b-form-input
                  v-model="title"
                  id="h-title"
                  placeholder="Enter Vehicle Defect Title.."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-form-group
                label="Status"
                label-for="h-status-name"
                label-cols-md="4"
              >
                <v-select
                  id="h-status-name"
                  v-model="status"
                  label="label"
                  :options="statusOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-form-group
                label=""
                label-for="h-status-name"
                label-cols-md="4"
              >
                <b-button class="btn-sm" variant="primary" @click="onSave">
                  Submit
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>
      </b-tab>
     
    </b-tabs>

  </b-card>
</template>
            
<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormTimepicker,
  BFormDatepicker,
  BDropdownItem,
  BDropdown,
  BLink,
  BPagination,
  BFormSelect,
  BTabs,
  BTab
} from "bootstrap-vue";
// import vehicle_defects_category from "./vehicle_defects_category"
import { required, min, confirmed } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { serverUri } from "@/config";
import router from "@/router";
import { getUserData } from "@/auth/utils";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";
import vehicle_defects_categoryVue from './vehicle_defects_category.vue';

import draggable from 'vuedraggable'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    flatPickr,
    DateRangePicker,
    vSelect,
    BDropdownItem,
    BDropdown,
    BLink,
    BPagination,
    BFormSelect,
    BTabs,
    BTab,
    vehicle_defects_categoryVue,
    draggable
  },
  data() {
    return {
      checkbox: false,
      userId: getUserData().driver_id,
      companyId: getUserData().company_id,
      vehicle_defect_id: "",
      status: "",
      title: "",
      statusOptions: [
        { title: "1", label: "Active" },
        { title: "0", label: "Not in Service" },
      ],
      vehicleCategory: "",
      vehicleCategoryfilter: "",
      vehicleCategoryOptions: [],
      startDate: "",
      endDate: "",
      perPage: 25,
      selectedVehicle: "",
      selectedVehicleCategory: "",
      vehicle: "",
      pageOptions: [5, 10, 15, 25],
      option: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      history_list: [],
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      filter: " ",
      draggableOptions: {
        animation: 400,
      },
      showUpdate: false,
      fields: [
        {
          key: "category",
          label: "Category",
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "title",
          label: "Title",
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          formatter: (value) => {
            return value == 1 ? "Active" : "In-Active";
          },
        },
        {
          key: "action",
        },
      ],
    };
  },
  mounted() {
    this.getVehicleCategory();
  },
  computed: {},
  props: {},
  methods: {

    colChangeNew(e) {

      var details            = e.moved.element;
      this.vehicle_defect_id = details.id;
      this.vehicleCategory = {
        title: details.category_id,
        label: details.category,
      };
      this.title             = details.title;
      this.status            = { title: details.status, label: details.status == 1 ? "Active" : 'Not in Service' };
      this.order_by          = e.moved.newIndex;
      

      this.onSave(e.moved.oldIndex);
  },

    onChange() {
      this.selectedVehicleCategory = this.vehicleCategoryfilter?.title;
    },
    getVehicleDefectsList() {
      axios
        .get(`${serverUri}/inspections/vehicle_defects/${this.companyId}`)
        .then((res) => {
          console.log(res.data);
          this.history_list = res.data;
        });

      console.log(this.history_list);
    },

    getVehicleCategory() {
      axios
        .get(
          `${serverUri}/inspections/vehicle_defects_category/${this.companyId}`
        )
        .then((res) => {
          res.data.map((item) => {
            if (item.status == 1) {
              this.vehicleCategoryOptions.push({
                title: item.id,
                label: item.title,
              });
            }
          });
        });
    },

    onEdit(details) {
      this.vehicle_defect_id = details.id;
      this.vehicleCategory = {
        title: details.category_id,
        label: details.category,
      };
      this.title = details.title;
      this.status = {
        title: details.status,
        label: details.status == 1 ? "Active" : "Not in Service",
      };
      this.order_by          = details.order_by;

      this.$refs["modal-center-update-new"].show();
    },

    onDeleteDefect(details)
    {
      this.$swal({
                        title: "Are you sure to delete this defect?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Save",
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.value) {

                            axios
                              .post(`${serverUri}/fleet/deleteVehicleDefect`, {
                                id: details.id,
                              })
                              .then((res) => {
                                if (res.data.status) {
                                  this.showToast(
                                    "success",
                                    "Success",
                                    "The Vehicle defects has been removed succesfully."
                                  );

                                  this.getVehicleDefectsList();
                                  
                                } else {
                                  this.showToast("warning", "Failure", res.data.message);
                                }
                              });

                        }
                      })
    },
    onSave(oldIndex = null) {
      if (this.vehicle_defect_id) {
        axios
          .post(`${serverUri}/inspections/vehicle_defects/edit`, {
            company_id: this.companyId,
            title: this.title,
            category_id:
              this.vehicleCategory.title == undefined
                ? ""
                : this.vehicleCategory.title,
            vehicle_defect_id: this.vehicle_defect_id,
            status: this.status.title == undefined ? "" : this.status.title,
            order : this.order_by,
            old_index : oldIndex


          })
          .then((res) => {
            if (res.data.status) {
              this.showToast(
                "success",
                "Success",
                "The Vehicle defects has been saved."
              );
              this.$refs["modal-center-update-new"].hide();

              this.title = "";
              this.vehicleCategory = "";
              this.status = "";
              this.vehicle_defect_id = "";
              this.order_by = "";

              this.getVehicleDefectsList();
            } else {
              this.showToast("warning", "Failure", res.data.message);
            }
          });
      } else {
        axios
          .post(`${serverUri}/inspections/vehicle_defects/add`, {
            company_id: this.companyId,
            title: this.title,
            category_id:
              this.vehicleCategory.title == undefined
                ? ""
                : this.vehicleCategory.title,
            status: this.status.title == undefined ? "" : this.status.title,
            order : parseInt(this.history_list.length) + 1

          })
          .then((res) => {
            if (res.data.status) {
              this.showToast(
                "success",
                "Success",
                "The Vehicle defects has been saved."
              );
              this.$refs["modal-center-new"].hide();

              this.title = "";
              this.vehicleCategory = "";
              this.status = "";
              this.order_by = "";

              this.getVehicleDefectsList();
            } else {
              this.showToast("warning", "Failure", res.data.message);
            }
          });
      }
    },

    onAddVehcileDefect() {},

    filterAllData() {
      this.showUpdate = true;
      // this.getClockHistory();
      axios.get(`${serverUri}/inspections/vehicle_defects`).then((res) => {
        console.log(res.data);
        this.history_list = res.data;
      });
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    this.getVehicleDefectsList();
  },
};
</script>
            
            <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.edit-header {
  display: flex;
  align-items: center;
}
</style><style>
.permission {
  margin: 10px 0;
}

.permission div {
  margin: 2px 0;
}
button.btn.btn-danger {
  margin-left: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f22323;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*
.dropdown {
  top: -20px;
}
*/
/* .modal-content {
  height: 384px;
} */
</style>
            